import React, { useState } from 'react';
import { useEffect } from 'react';
import { ArrayInput, NumberInput, SelectInput, SimpleFormIterator, useDataProvider, useRedirect } from 'react-admin';
import { Card, Typography } from '@mui/material';

const ExtensionDnDList = ({record}) => {
  const [extensions, setExtensions] = useState([]);
  const [categories, setCategories] = useState([]);
  const redirect = useRedirect();

  const provider = useDataProvider();

  const getCategories = () => {
    provider
      .getList('categories', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data }) => {
        setCategories(data);
      });
  };

  const getExtensions = () => {
    provider
      .getList('extensions', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data }) => {
        setExtensions(data);
      })
      .catch((error) => {
        console.log(error);
        redirect('/login');
      });
  };

  useEffect(()=> {
    getExtensions();
    getCategories();
  },[])

  return !categories.length && !extensions.length ? <h1>Something is wrong. </h1> :
   (
    <>
      <Card sx={{width: '100%', display: 'flex', padding: '1em', flexDirection: 'column'}} variant='outlined'>
          <h2>Zusatzleistungen</h2>
            <ArrayInput source="cat" label="" >
                <SimpleFormIterator inline>
                  <SelectInput sx={{width: '30vw'}} label='Kategorie' source={`catId`} choices={categories} optionText={category => `${category.id} - ${category.thema}`} />
                  <SelectInput sx={{width: '30vw'}} label='Zusatzleistung' source={`extId`} choices={extensions} optionText={extension => `${extension.id} - ${extension.name}`} />
                  <NumberInput sx={{width: '10vw'}} label='Prio' source={`prio`} defaultValue={0} />
                </SimpleFormIterator>
            </ArrayInput>
      </Card>
    </>
  );
};

export default ExtensionDnDList;
