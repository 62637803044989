import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

const QuestionEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="id" />
            <NumberInput source="nr" />
            <TextInput source="frage" />
            <TextInput source="erklaerung" />
            <NumberInput source="kategorie" />
        </SimpleForm>
    </Edit>
);

export default QuestionEdit;