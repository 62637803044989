import CountUp from 'react-countup';
import styled from 'styled-components';

const Container = styled.div`
    background-color: '#1E1E1E !important';
    position: relative;
    font-weight: 900;
    font-size: 3rem;
    `

const CustomerBubble = ({count}) => {
    return (
        <Container className="container">
            <CountUp
                useEasing={true}
                start={0}
                end={count}
                duration={1.75}
            />
        </Container>
    );
};

export default CustomerBubble;