
import { Create, email, ImageField, ImageInput, NumberInput, required, SimpleForm, TextInput } from 'react-admin';

const CustomerCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="email" validate={[required(), email()]} fullWidth />
            <NumberInput source="affiliate_ref" defaultValue={0}/>
            <NumberInput source="shop_available" defaultValue={0}/>
            <NumberInput source="locally_available" defaultValue={0}/>
        </SimpleForm>
    </Create>
    // https://health-coach.digital/?=t03022acfdf2060757ad4d8d0449c1588
);

export default CustomerCreate;