import * as React from "react";
import { Admin, Resource } from "react-admin";
import QuestionList from './components/QuestionList';
import QuestionEdit from './components/QuestionEdit';
import CustomerList from './components/CustomerList';
import CustomerEdit from './components/CustomerEdit';
import CustomerCreate from './components/CustomerCreate';
import {Dashboard} from './components/dashboard';
import {authProvider} from './utils/authProvider';
import QuizIcon from '@mui/icons-material/Quiz';
import GroupIcon from '@mui/icons-material/Group';
import ExtensionIcon from '@mui/icons-material/Extension';
import ExtensionList from './components/ExtensionList';
import ExtensionEdit from './components/ExtensionEdit';
import ExtensionCreate from './components/ExtensionCreate';
import { ThemesEdit } from './components/ThemesEdit';
import { ThemesCreate } from './components/ThemesCreate';
import { ThemeList } from './components/ThemesList';
import { dataProvider } from './services/dataProvider';
import SliderList from './components/SliderList';
import SliderEdit from './components/SliderEdit';
import SliderCreate from './components/SliderCreate';

/**
 * Main component of the application.
 * @returns 
 */
const App = () => 
    <Admin authProvider={authProvider} dataProvider={dataProvider} dashboard={Dashboard} requireAuth title={"Health Coach digital"}>
        <Resource name="customers" list={CustomerList} edit={CustomerEdit} create={CustomerCreate} icon={GroupIcon} options={{ label: 'Kunden' }}/>
        <Resource name="themes" list={ThemeList} edit={ThemesEdit} create={ThemesCreate} options={{ label: 'Themes' }}/>
        <Resource name="extensions" list={ExtensionList} edit={ExtensionEdit} create={ExtensionCreate} icon={ExtensionIcon} options={{ label: 'Zusatzleistungen' }}/>
        <Resource name="questions" list={QuestionList} edit={QuestionEdit} icon={QuizIcon} options={{ label: 'Fragen' }}/>
        <Resource name="slider" list={SliderList} edit={SliderEdit} create={SliderCreate} options={{ label: 'Slider' }}/>
    </Admin>;

export default App;