import { Edit, FormDataConsumer, ReferenceInput, SimpleForm, TextInput, useEditController, useInput } from "react-admin";
import { useFormState } from "react-final-form";
import { ImageUploadInput } from "./ImageUploadInput";
import { RichTextInput } from "ra-input-rich-text";

/**
 * Edit component for the Extension resource
 * @returns {React.Component} The edit component
 */
const ExtensionEdit = (props) => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" label="Name" required />
        <TextInput source="headline" label="Überschrift" required />
        <RichTextInput source="description" label="Beschreibung" multiline fullWidth />
        <TextInput source="bloblink" label="Link" fullWidth />
        <ImageUploadInput source="bloblink" />
      </SimpleForm>
    </Edit>
  );
};

export default ExtensionEdit;
