export const authProvider = {
    // called when the user attempts to log in
    login: ({username, password}) => {
      // https://dev.health-coach.digital/api/admin/login
      // Ex. {email: "admin", password: "admin"}

        const request = new Request('https://dev.health-coach.digital/api/v2/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    if (401 === response.status) {
                      localStorage.removeItem('token');
                      window.location = '/#/login';
                    }
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(res => {
                localStorage.setItem('token', res.token);
            })
            .catch(() => {
                throw new Error('Network error')
            });

    },
    // called when the user clicks on the logout button
    logout: () => {
      localStorage.removeItem("token");
      return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
      if (status === 401 || status === 403) {
        localStorage.removeItem("token");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      return localStorage.getItem("token")
        ? Promise.resolve()
        : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
  };