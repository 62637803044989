import { Create, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

export const ThemesCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="font" />
            <ColorInput source="bodyColor" />
            <ColorInput source="bodyText" />
            <ColorInput source="primaryColor" />
            <ColorInput source="primaryContrast" />
            <ColorInput source="buttonColor" />
            <ColorInput source="buttonText" />
        </SimpleForm>
    </Create>
);