import { Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from 'react';
import { useAuthenticated, useDataProvider } from 'react-admin';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import useCustomerCount from '../hooks/useCustomerCount';
import usePDFCreationCount from '../hooks/usePDFCreationCount';
import CustomerBubble from './CustomerBubble';
import usePDFCustomerCount from '../hooks/usePDFCustomerCount';
import _ from 'lodash';
import moment from 'moment';

const StyledCard = styled(Card)`
  margin: 1em;
  max-width: 187px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  `

// function downloadFile (url, token) {
//   return fetch(url, {
//     mode: 'cors',
//     method: 'GET',
//     headers: {
//       'Authorization': 'Bearer ' + token,
//       'Content-Type': 'application/pdf',
//       'Accept': 'application/pdf'
//     }
//   }).then(resp => resp.blob())
//     .then(blob => {
//        //Build a URL from the file
//        const fileURL = URL.createObjectURL(blob);
//        //Open the URL on new Window
//         const pdfWindow = window.open();
//         pdfWindow.location.href = fileURL;   
//     })
// }

// const downloadPdf = () => {
//   downloadFile('/api/v2/pdf', localStorage.getItem('token'));
// }

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pdfCustomerCountState, setPdfCustomerCountState] = useState([]);
  const [pdfCustomerCountWithTimestampState, setPdfCustomerCountWithTimestampState] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment('2021-01-01'),
    endDate: moment('2024-12-31')
  });

  const [affiliates, setAffiliates] = useState([]);

  useAuthenticated();
  const {count: customercount} = useCustomerCount();
  const {count: pdfcount} = usePDFCreationCount();
  const {data: pdfCustomerCount, dataWithTimestamp: pdfCustomerCountWithTimestamp} = usePDFCustomerCount();

  useEffect(() => {
    const username = process.env.REACT_APP_GZ_USER;
    const password = process.env.REACT_APP_GZ_TOKEN;
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    
    if(!affiliates.length > 0) {
      fetch('https://gesundezelle24.com/wp-json/affwp/v1/affiliates?number=10000', {
        method: 'GET',
        headers: headers
      }).then(resp => resp.json()).then(data => {
        console.log(data);
        setAffiliates(data);
      }
      );
    }
  },[]);

  useEffect(() => {
    if (pdfCustomerCount) {
      setPdfCustomerCountState(pdfCustomerCount);
    }
    if (pdfCustomerCountWithTimestamp) {
      setIsLoading(true);
      const filtered = _(pdfCustomerCountWithTimestamp).filter((item) => {
        return moment(item.timestamp).isBetween(dateRange.startDate, dateRange.endDate);
      }).groupBy('company_name')
      .map((values, key) => ({ company_name: key, count: values.length }))
      .value();

      setPdfCustomerCountWithTimestampState(filtered);
    }
    setIsLoading(false);
  }, [pdfCustomerCount, pdfCustomerCountWithTimestamp, dateRange]);

  return (
  <Card>
    <CardHeader title="Health Coach digital - Administration" />
    <CardContent style={{display: 'grid', width: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <StyledCard>
          <CardHeader title="Kunden" />
          <CardContent>
            <CustomerBubble count={customercount}/>
          </CardContent>
        </StyledCard>
        <StyledCard>
          <CardHeader title="PDFs erstellt" />
          <CardContent>
            <CustomerBubble count={pdfcount}/>
          </CardContent>
        </StyledCard>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{width: '50%', height: '400px'}}>
          <h4>PDF (kundenspezifisch)</h4>
          <div style={{margin: '28px', display: 'flex', flexDirection: 'row', gap:'160px'}}>
            <div>
              Von: <input type="date" onChange={e => setDateRange(p => ({startDate: moment(e.target.value), endDate: p.endDate}))} ></input>
            </div>
            <div>
              Bis: <input type="date" onChange={e => setDateRange(p => ({startDate: p.startDate, endDate: moment(e.target.value)}))} ></input>
            </div>
          </div>
          <BarChart
            width={500}
            height={300}
            data={pdfCustomerCountWithTimestampState}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="company_name" />
            <YAxis label={{value: "Anzahl", angle: -90, position: 'insideLeft' }} tickCount={1}/>
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8"  />
          </BarChart>
        </div>
          <table style={{ 
            borderCollapse: 'collapse',
            margin: '25px 0',
            fontSize: '0.9em',
            fontFamily: 'sans-serif',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
            display: 'block',
            height:'400px',
            overflowY: 'scroll'
            }}>
            <thead style={{position: 'sticky', top: 0}}>
                <tr style={{
                  backgroundColor: '#3a9800',
                  color: '#ffffff',
                  textAlign: 'left'
                }}>
                    <th style={{
                      padding: '12px 15px'
                    }}>Position</th>
                    <th style={{
                      padding: '12px 15px'
                    }}>Name</th>
                    <th style={{
                      padding: '12px 15px'
                    }}>Anzahl</th>
                </tr>
            </thead>
            <tbody>
            {_.orderBy(pdfCustomerCountWithTimestampState, 'count', 'desc').map((item, index) => {
                return (<tr key={index} style={{
                  borderBottom: '1px solid #dddddd'
                }}>
                  <td style={{
                    padding: '12px 15px'
                  }}>{index + 1}</td>
                  <td style={{
                    padding: '12px 15px'
                  }}>{item.company_name}</td>
                  <td style={{
                    padding: '12px 15px'
                  }}>{item.count}</td>
                  </tr>)
              })}
            </tbody>
        </table>
              
              </div>

            <h3>Besuche:</h3>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
              <table style={{ 
            borderCollapse: 'collapse',
            margin: '25px 0',
            fontSize: '0.9em',
            fontFamily: 'sans-serif',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
            display: 'block',
            height:'400px',
            overflowY: 'scroll'
            }}>
            <thead style={{position: 'sticky', top: 0}}>
                <tr style={{
                  backgroundColor: '#986800',
                  color: '#ffffff',
                  textAlign: 'left'
                }}>
                    <th style={{
                      padding: '12px 15px'
                    }}>Position</th>
                    <th style={{
                      padding: '12px 15px'
                    }}>REF</th>
                    <th style={{
                      padding: '12px 15px'
                    }}>E-Mail</th>
                    <th style={{
                      padding: '12px 15px'
                    }}>Besucher</th>
                    <th style={{
                      padding: '12px 15px'
                    }}>Ausgezahlt</th>
                    <th style={{
                      padding: '12px 15px'
                    }}>Ausstehen</th>
                </tr>
            </thead>
            <tbody>
            {_.orderBy(affiliates, 'visits', 'desc').map((item, index) => {
                return (<tr key={index} style={{
                  borderBottom: '1px solid #dddddd'
                }}>
                  <td style={{
                    padding: '12px 15px'
                  }}>{index + 1}</td>
                  <td style={{
                    padding: '12px 15px'
                  }}>{item.affiliate_id}</td>
                  <td style={{
                    padding: '12px 15px'
                  }}>{item.payment_email}</td>
                  <td style={{
                    padding: '12px 15px'
                  }}>{item.visits}</td>
                  <td style={{
                    padding: '12px 15px'
                  }}>{item.earnings} €</td>
                  <td style={{
                    padding: '12px 15px'
                  }}>{item.unpaid_earnings} €</td>
                  </tr>)
              })}
            </tbody>
        </table>
          </div>
    </CardContent>
  </Card>
)};