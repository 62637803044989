import { Datagrid, List, TextField } from 'react-admin';
import { ColorField } from 'react-admin-color-picker';

export const ThemeList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="font" />
            <ColorField source="bodyColor" />
            <ColorField source="bodyText" />
            <ColorField source="primaryColor" />
            <ColorField source="primaryContrast" />
            <ColorField source="buttonColor" />
            <ColorField source="buttonText" />
        </Datagrid>
    </List>
);