import { useState, useEffect } from 'react';
import { useRedirect } from 'react-admin';
import useApi from './useApi';
import _ from 'lodash';

const usePDFCustomerCount = () => {
    const redirect = useRedirect();
    const [data, setData] = useState([]);
    const [dataWithTimestamp, setDataWithTimestamp] = useState([]); // [{company_name: 'test', count: 1, timestamp: '2021-01-01 00:00:00'}
    const api = useApi(process.env.REACT_APP_API_URL);
    
    useEffect(() => {
        api.get('/pdfcustomercount').then((response) => {
            setDataWithTimestamp(response.data);
            const transformedData = _(response.data)
              .groupBy('company_name')
              .map((values, key) => ({ company_name: key, count: values.length }))
              .value();
            setData(transformedData);
        }).catch((error) => {
            if (error.response.status === 500) {
                redirect('/login');
            }
        });
    },[]);

    return { data, dataWithTimestamp };
};

export default usePDFCustomerCount;