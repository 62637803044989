import { dataProvider as customProvider } from './customerDataProvider';

// const dataProvider = jsonServerProvider('https://dev.health-coach.digital/api/v2', httpClient);
// const dataProvider = jsonapiClient('https://dev.health-coach.digital/api/admin');

/**
 * Creates a new data provider by copying properties from customProvider and then modifying the update method and adding a customerscount method.
 */
export const dataProvider = {
    ...customProvider,

    // Modifies the update method to handle customers resource and logo data. If the resource is not customers, it delegates to the parent customProvider. 
    // If logo data exists in params, it appends it to formData before delegating to parent customProvider for update.
    update: (resource, params) => {
        if (resource !== 'customers') {
            return customProvider.update(resource, params);
        }

        if (params.data.logo) {
            const formData = new FormData();
            formData.append('logo', params.data.logo.rawFile);
            return customProvider.update(resource, {
                ...params,
                data: { ...params.data, logo: params.data.logo.rawFile }
            });
        } else {
            return customProvider.update(resource, params);
        }
    },

    customerscount: () => {
        return fetch(`/customerscount`)
            .then(response => response.json());
    }
};
