import { useFormControl } from '@mui/material';
import { useState } from "react";
import { useEditController, useInput, useNotify, useRecordContext } from "react-admin";

export const ImageUploadInput = (props) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const notfiy = useNotify();
    const record = useRecordContext();
    const control = useInput(props);
    console.log({control})

    const uploadLogo = (image) => {
        if (image) {
            setSelectedImage(image);
            const formData = new FormData();
            formData.append("image", image);
            formData.append("id", record.id);
            fetch("https://dev.health-coach.digital/api/v2/imageupload", {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log("Success:", result);
                    // inputLink.field.value = result;
                    control.field.onChange(result.publicUrl);
                    notfiy("Bild wurde erfolgreich hochgeladen");
                })
                .catch((error) => {
                    console.error("Error:", error);
                    notfiy("Bild konnte nicht hochgeladen werden");
                });
        }
    };

    return (<div>
        <h4>Bild hochladen</h4>

        <br />
        <input
            type="file"
            name="logo"
            onChange={(event) => {
                uploadLogo(event.target.files[0]);
            }} />
        {selectedImage && (
            <div>
                <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                <br />
                <button onClick={() => setSelectedImage(null)}>Entfernen</button>
            </div>
        )}
        <br />
    </div>);

};
