import { Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

const ExtensionCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" label="Name" required />
            <TextInput source="headline" label="Überschrift" required/>
            <TextInput source="description" label="Beschreibung" />
            <TextInput source="bloblink" label="Link" />
        </SimpleForm>
    </Create>
);

export default ExtensionCreate;