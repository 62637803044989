import { Datagrid, List, NumberField, TextField, TextInput } from 'react-admin';

const filters = [
    <TextInput label="Suche" source="name" alwaysOn />,
];

const ExtensionList = () => (
    <List filters={filters} debounce={1000}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="headline" />
            <TextField source="bloblink" />
        </Datagrid>
    </List>
);

export default ExtensionList;