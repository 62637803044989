import { Grid } from "@mui/material";
import { useState } from "react";
import {
  Edit,
  EditBase,
  EditView,
  FunctionField,
  Labeled,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useEditContext,
  useEditController,
  useNotify,
  useRefresh,
  WithRecord,
} from "react-admin";
import styled from "styled-components";
import ExtensionDnDList from "./ExtensionDnDList";

const Box = styled.div`
  border: 1px solid #ccc;
  padding: 1em;
`;

// ex. https://dev.health-coach.digital/api/v2/customers/1673437e4e27f5a22c71cf51d0a93907/extensions
const EditViewToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const EditViewTheme = ({ id }) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify("Theme updated", "info", {}, true);
  };

  const onError = (error) => {
    notify(`Error: ${error.message}`, "warning");
  };

  const onMutate = () => {
    notify("Updating theme...", "info", {}, true);
  };

  if (!id) return <div>no id</div>;

  return (
    <EditBase resource="themes" id={id} mutationOptions={{ onSuccess, onError, onMutate }}>
      <SimpleForm sx={{ display: "flex" }} toolbar={<EditViewToolbar />}>
        <TextInput source="id" />
        <TextInput source="name" />
        <TextInput source="font" />
        <TextInput source="bodyColor" />
        <TextInput source="bodyText" />
        <TextInput source="primaryColor" />
        <TextInput source="primaryContrast" />
        <TextInput source="buttonColor" />
        <TextInput source="buttonText" />
      </SimpleForm>
    </EditBase>
  );
};

const CustomEdit = ({ record }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const props = useEditController();
  const [theme, setTheme] = useState(props?.record?.theme_id);

  const selectThemeHandler = (event) => {
    setTheme(event.target.value);
  };

  const uploadLogo = (logo) => {
    if (logo) {
      setSelectedImage(logo);
      const formData = new FormData();
      formData.append("logo", logo);
      formData.append("id", props.record.id);
      fetch("https://dev.health-coach.digital/api/v2/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("Success:", result);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <Edit>
      <SimpleForm warnWhenUnsavedChanges>
        <TextField source="company_name" fullWidth variant='h4' />
        <Labeled label="Link">
          <FunctionField
            label="Link"
            source="id"
            render={(record, _source) => (
              // react admin button with external url link
              <a href={"https://health-coach.digital?t=" + record.id} target="_blank" rel="noreferrer">
                {"https://health-coach.digital?t=" + record.id}
              </a>
            )}
          />
        </Labeled>
        <TextInput source="id" fullWidth disabled />

        <Grid container spacing={2}>
          {/* Contactperson */}
          <Box style={{margin: '12px', width: '100%'}}>
            <h2>Kontaktperson</h2>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput source="email" fullWidth label="E-Mail"/>
              </Grid>
              <Grid item xs={4}>
                <TextInput source="contact_person" fullWidth label="Name" />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="contact_phone" fullWidth label="Telefon" />
              </Grid>
            </Grid>
          </Box>

          {/* Company */}
          <Box style={{margin: '12px', width: '100%'}}>
            <h2>Studio</h2>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput source="company_name" fullWidth label="Firma" />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="company_shortname" fullWidth label="Studiobezeichnung Erfolgsplan" />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="company_street" fullWidth label="Straße" />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="company_zip" fullWidth label="PLZ" />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="company_city" fullWidth label="Stadt" />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="company_email" fullWidth label="E-Mail" />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="company_website" fullWidth label="Webseite"/>
              </Grid>
              <Grid item xs={4}>
                <TextInput source="company_phone" fullWidth label="Telefon" />
              </Grid>
            </Grid>
          </Box>

          <Box style={{margin: '12px', width: '100%'}}>
            <h2>Einstellungen</h2>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <NumberInput source="affiliate_ref" fullWidth />
              </Grid>
              <Grid xs={8}></Grid>
              <Grid item xs={4}>
                <NumberInput source="shop_available" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <NumberInput source="locally_available" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <NumberInput source="good_for_you_available" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <NumberInput source="slider" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <ReferenceInput label="Theme" source="theme_id" reference="themes">
                  <SelectInput optionText="name" onChange={selectThemeHandler} emptyText={"Bitte auswählen"} fullWidth />
                </ReferenceInput>
              </Grid>
            </Grid>
            <div>
              <h3>Logo Upload</h3>

              <br />
              <input
                type="file"
                name="logo"
                onChange={(event) => {
                  uploadLogo(event.target.files[0]);
                }}
              />
              {selectedImage && (
                <div>
                  <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                  <br />
                  <button onClick={() => setSelectedImage(null)}>Entfernen</button>
                </div>
              )}
              <br />
            </div>
          </Box>
        </Grid>


        <WithRecord
          render={(record) => {
            return <ExtensionDnDList record={record} />;
          }}
        />
      </SimpleForm>
    </Edit>
    // https://health-coach.digital/?=t03022acfdf2060757ad4d8d0449c1588
  );
};

export default CustomEdit;
