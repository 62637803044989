import { useState, useEffect } from 'react';
import { useRedirect } from 'react-admin';
import useApi from './useApi';

const useCustomerCount = () => {
    const redirect = useRedirect();
    const [count, setCount] = useState(null);
    const api = useApi(process.env.REACT_APP_API_URL);
    
    useEffect(() => {
        api.get('/customerscount').then((response) => {
            setCount(response.data);
        }).catch((error) => {
            if (error.response.status === 500) {
                redirect('/login');
            }
        });
    });

    return { count };
};

export default useCustomerCount;