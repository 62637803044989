import axios from 'axios';

const useApi = (baseURL) => {
    const token = localStorage.getItem('token');

    if(!token) {
        throw new Error('No token available');
    }
    
    const api = axios.create({
        baseURL,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return api;
};

export default useApi;