import { Create, SimpleForm, TextInput } from 'react-admin';

const SliderCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="text" />
        </SimpleForm>
    </Create>
);

export default SliderCreate;