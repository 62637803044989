import { List, SimpleList } from 'react-admin';

const SliderList = () => (
    <List title={'Slider'}>
        <SimpleList
        primaryText={(record) => record.id}
        secondaryText={(record) => record.text}
        />
    </List>
    )

export default SliderList;