import { fetchUtils, withLifecycleCallbacks } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const fetchJson = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }

  options.user = {
      authenticated: true,
      token: 'Bearer ' + localStorage.getItem('token')
  };
  
  return fetchUtils.fetchJson(url, options).then(response => {
    if(response.status === 500) {
      window.location.href = '/login';
    }
    return response;
  });
};

const baseDataProvider =  simpleRestProvider(process.env.REACT_APP_API_URL, fetchJson);

export const dataProvider = withLifecycleCallbacks(baseDataProvider, [
    {
        resource: 'customerscount',
        fetch: async () => {
            const { data } = await fetchUtils.fetchJson('/customerscount').then(response => response.json());
            return data;
        },
    },
    {
      resource: 'customers',
      afterGetOne: async (params, dataProvider) => {
        params.data = {
          ...params.data,
          cat:[].concat(...params.data.categories.map(({id: catId, extensions}) =>
            extensions.map(({id: extId, pivot}) => ({catId, extId, prio: pivot.prio}))
          ))
        }
        return params;
      },

    }
]);