import { Datagrid, BooleanField, EmailField, List, NumberField, FunctionField, Button, EditButton, useRecordContext, useAuthenticated, TextField } from 'react-admin';

/**
 * Button to copy the link to the clipboard
 * @param {*} props 
 * @returns Button Component
 */
const CopyLinkBtn = (props) => {
    const record = useRecordContext();
    const handleClick = () => {
        var data = "https://health-coach.digital?t=" + record.id;

        navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
            if (result.state === 'granted') {
                navigator.clipboard.writeText(data).then(function() {
                  console.log("Copied to clipboard successfully!");
                }, function(error) {
                  console.error("unable to write to clipboard. Error:");
                  console.log(error);
                });
            } else {
                console.log("clipboard-permission not granted: " + result);
            }
        });
    };
    return <Button onClick={handleClick} label={'Kopieren'} {...props} />;
 };

const CustomerList = () => {
    useAuthenticated();

    return (
    <List>
        <Datagrid>
            <TextField source="company_name" label="Firma" />
            <EmailField source="email" label="Email" />
            <NumberField source="affiliate_ref" label="Affiliate ID" />
            <FunctionField source="shop_available" label="Shop" render={(record,source) => 
                <BooleanField record={{...record,shop_available:!!record.shop_available}} source={source}/>}/>
            <FunctionField source="locally_available" label="Lokal" render={(record,source) => 
                <BooleanField record={{...record,locally_available:!!record.locally_available}} source={source}/>}/>
            <FunctionField label="Link" source="id" render={(record) => 
                <a href={"https://health-coach.digital?t=" + record.id} target="_blank" rel="noreferrer">{"https://health-coach.digital?t=" + record.id}</a>
            }/>
            {/* Action Buttons */}
            <CopyLinkBtn label="Kopieren"/>
            <EditButton label=''/>
        </Datagrid>
    </List>
)};

export default CustomerList;
